import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, MaxLength, IsEmail, validate } from "class-validator";

import { SideNav } from "@/layout";
import { Lightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { StudentModel } from "@/models";
import { Common } from "../Common";

export class StudentForm{
  @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(50, { message: "最多輸入50字" }) @IsEmail({}, { message: "請輸入有效信箱" }) acc = '';
  @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(30, { message: "最多輸入30字" }) name = '';
  @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(20, { message: "最多輸入20字" }) phone = '';
  @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(30, { message: "最多輸入30字" }) parentName = '';
  @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(20, { message: "最多輸入20字" }) parentPhone = '';
  @IsDefined() @IsNotEmpty({ message: "必填" }) cid = '';
  @IsDefined() @IsNotEmpty({ message: "必填" }) crid = '';
  @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(50, { message: "最多輸入50字" }) address = '';
  pic = '';
  @IsDefined() @IsNotEmpty({ message: "必填" }) sid = '';
  @IsDefined() @MaxLength(200, { message: "最多輸入200字" }) note = '';
  active = 1;
}

@Component<AdminStudentAddController>({
  components: {
    SideNav, Lightbox
  }
})
export default class AdminStudentAddController extends Vue {
    /*定義data 變數 */
    private doubleClick = false; 
    private studentForm = new StudentForm();
    private cityList: object = {};
    private regionList: object = {};
    private schoolCityList: { [key: string]: any} = {};
    private schoolList: { [key: string]: any} = {};
    private token = window.localStorage.getItem('adminToken') as string;
    private identity = window.localStorage.getItem('admin_identity') as string;
    private city = '';

    private errorMsg = "";
    private openLB = false;
    private errNo = 0;
    private errorMsgMap: { [key: string]: string } = {
        acc: '',
        name: '',
        phone: '',
        parentName: '',
        parentPhone: '',
        cid: '',
        crid: '',
        address: '',
        pic: '',
        sid: '',
        note: '',
    };

    public async created() {
        this.cityList = await Common.getAllCity(); //取得所有縣市

        if(this.identity === "1"){
            this.schoolCityList = await Common.getSchoolCity(); //取得有分校之縣市
        }else{
            const sid = window.localStorage.getItem('admin_sid') as string;
            this.schoolCityList = await Common.getCity(sid); //取得該分校之縣市
            this.city = this.schoolCityList[0].cid;

            const school = window.localStorage.getItem('admin_school') as string;
            this.schoolList[0] =  {
                sid: sid,
                name: school,
            };
            this.studentForm.sid = sid;
        }
    }

    //取得該縣市之鄉鎮市區
    private async getRegion() {
        this.studentForm.crid = '';
        const cid = this.studentForm.cid;
        this.regionList = await Common.getRegion(cid);
    }

    //取得該縣市之分校
    private async getSchool(city: string) {
        if(this.identity == "1"){ //權限為分校者，不連動分校列表
            this.studentForm.sid = '';
            this.schoolList = await Common.getSchool(city);
        }
    }

    private changefile(event: any) {
        const file = event.target.files[0];

        if(file) { //若有上傳圖片
            /* 限制檔案上傳型別 */
            const suffixName = file.name.substring(file.name.lastIndexOf('.') + 1);   // 得到檔案字尾名
            if (suffixName !== 'jpg' && suffixName !== 'png' && suffixName !== 'jpeg') {
                this.errorMsg = '上傳檔案只能是 jpg、png、jpeg 格式！';
                this.openLB = true;
                return;
            }
            /* 限制檔案上傳大小 */
            const isLimitSize = file.size / 1024 / 1024 < 3;
            if (!isLimitSize) {
                this.errorMsg = '上傳檔案不能超過 3MB！';
                this.openLB = true;
                return;
            }
            const reader = new FileReader();

            reader.onload = (e:any) => {
                this.studentForm.pic = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    private validateAndSubmit() {
        validate(this.studentForm, { skipMissingProperties : true }).then(
            async result => {
                if(result.length !== 0) {              
                    this.errorMsgMap = { //先清空error message
                        acc: '',
                        name: '',
                        phone: '',
                        parentName: '',
                        parentPhone: '',
                        cid: '',
                        crid: '',
                        address: '',
                        pic: '',
                        sid: '',
                        note: '',
                    };
                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                    return;
                }

                if(!this.doubleClick) {
                    this.doubleClick = true; 

                    const formobj: HTMLFormElement = document.getElementById('studentForm') as HTMLFormElement;
                    const data = new FormData(formobj); //創建空的Formdata object

                    data.append('token', this.token);

                    this.errNo = await StudentModel.add(data);
                    Common.checkToken(this.errNo);
                    this.openLB = true;
                    this.doubleClick = false;
                    this.errorMsg = ErrorMessage[this.errNo];
                }
            }
        )
    }

    private closeLB() {
        this.openLB = false;
        if (this.errNo === ErrorCode.Success) {
            this.$router.push("/admin/student");
        }
    }
}
